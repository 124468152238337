const BASEURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;
//const BASEURL = process.env.REACT_APP_API_PROD;

export const ENDPOINT_APP_NOTIFICACOES = {url: BASEURL + "app/notificacoes", method: "POST"};

export const ENDPOINT_ANEXO4_PESQUISAR = {url: BASEURL + "anexo4/pesquisar", method: "POST"};
export const ENDPOINT_ANEXO4_GRAVAR = {url: BASEURL + "anexo4/gravar", method: "POST"};
export const ENDPOINT_ANEXO4_EXCLUIR = {url: BASEURL + "anexo4", method: "DELETE"};

export const ENDPOINT_ARQUIVO_RETORNO_PROCESSAR = {url: BASEURL + "arquivoRetorno/processar", method: "POST"};

export const ENDPOINT_AGENDA_GET_HORARIO = {url: BASEURL + "agenda/getHorario", method: "POST"};
export const ENDPOINT_AGENDA_AGENDAR = {url: BASEURL + "agenda/agendar", method: "POST"};
export const ENDPOINT_AGENDA_ALTERAR_SITUACAO_HORARIO = {url: BASEURL + "agenda/alterarSituacaoHorario", method: "POST"};
export const ENDPOINT_AGENDA_ATUALIZAR = {url: BASEURL + "agenda/atualizar", method: "POST"};
export const ENDPOINT_AGENDA_COLAR_HORARIO = {url: BASEURL + "agenda/colarHorario", method: "POST"};
export const ENDPOINT_AGENDA_CRIAR = {url: BASEURL + "agenda/criar", method: "POST"};
export const ENDPOINT_AGENDA_EXCLUIR = {url: BASEURL + "agenda/excluir", method: "DELETE"};
export const ENDPOINT_AGENDA_IMPRIMIR = {url: BASEURL + "agenda/imprimir", method: "POST"};
export const ENDPOINT_AGENDA_LIBERAR_HORARIO = {url: BASEURL + "agenda/liberarHorario", method: "POST"};
export const ENDPOINT_AGENDA_LISTAR_HORARIOS = {url: BASEURL + "agenda/listarHorarios", method: "POST"};
export const ENDPOINT_AGENDA_LISTAR_PROFISSIONAIS_POR_PERIODO = {url: BASEURL + "agenda/listarProfissionaisPorPeriodo", method: "POST"};
//export const ENDPOINT_AGENDA_MARCAR_ATENDIDO = {url: BASEURL + "agenda/marcarAtendido", method: "POST"};
//export const ENDPOINT_AGENDA_MARCAR_NAO_COMPARECEU = {url: BASEURL + "agenda/marcarNaoCompareceu", method: "POST"};
export const ENDPOINT_AGENDA_PESQUISAR = {url: BASEURL + "agenda/pesquisar", method: "POST"};
export const ENDPOINT_AGENDA_REMOVER_SUSPENSAO_HORARIO = {url: BASEURL + "agenda/removerSuspensao", method: "POST"};
export const ENDPOINT_AGENDA_SUSPENDER_HORARIO = {url: BASEURL + "agenda/suspenderHorario", method: "POST"};
export const ENDPOINT_AGENDA_SUSPENDER_LIBERAR_AGENDA = {url: BASEURL + "agenda/suspenderLiberarAgenda", method: "POST"};

export const ENDPOINT_AGENDA_ENCERRAMENTO_CALCULAR = {url: BASEURL + "agendaEncerramento/calcular", method: "POST"};
export const ENDPOINT_AGENDA_ENCERRAMENTO_GRAVAR = {url: BASEURL + "agendaEncerramento/gravar", method: "POST"};
export const ENDPOINT_AGENDA_ENCERRAMENTO_PESQUISAR = {url: BASEURL + "agendaEncerramento/pesquisar", method: "POST"};
export const ENDPOINT_AGENDA_ENCERRAMENTO_RELATORIO = {url: BASEURL + "agendaEncerramento/relatorio", method: "POST"};

export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_BUSCAR_CREDENCIADOS = {url: BASEURL + "atendimentoExameMedico/buscarCredenciados", method: "GET"};
//export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_CANCELAR_GUIA = {url: BASEURL + "atendimentoExameMedico/cancelarGuia", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_CONCLUIR_EMISSAO_GUIAS = {url: BASEURL + "atendimentoExameMedico/concluirEmissaoGuias", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_ENUMS = {url: BASEURL + "atendimentoExameMedico/enums", method: "GET"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_GET_FUNCAO = {url: BASEURL + "atendimentoExameMedico/getFuncao", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_GRAVAR_FUNCIONARIO = {url: BASEURL + "atendimentoExameMedico/gravarFuncionario", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_IMPRIMIR_ASO = {url: BASEURL + "atendimentoExameMedico/imprimirAso", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_IMPRIMIR_ASO_AVULSO = {url: BASEURL + "atendimentoExameMedico/imprimirAsoAvulso", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_IMPRIMIR_ASO_UNID_MOVEL = {url: BASEURL + "atendimentoExameMedico/imprimirAsoUnidadeMovel", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_IMPRIMIR_TODOS_ASOS_UNID_MOVEL = {url: BASEURL + "atendimentoExameMedico/imprimirTodosAsoUnidadeMovel", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_IMPRIMIR_TODOS_PED_EXAME_UNID_MOVEL = {url: BASEURL + "atendimentoExameMedico/imprimirTodosPedExamesUnidadeMovel", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_IMPRIMIR_FICHA_MEDICA = {url: BASEURL + "atendimentoExameMedico/imprimirFichaMedica", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_IMPRIMIR_FICHAS_MEDICAS_UNID_MOVEL = {url: BASEURL + "atendimentoExameMedico/imprimirFichasMedicasUnidadeMovel", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_IMPRIMIR_GUIAS = {url: BASEURL + "atendimentoExameMedico/imprimirGuias", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_IMPRIMIR_RECIBO = {url: BASEURL + "atendimentoExameMedico/imprimirRecibo", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_PESQUISAR_EMPRESA = {url: BASEURL + "atendimentoExameMedico/pesquisarEmpresa", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_PESQUISAR_FUNCIONARIO = {url: BASEURL + "atendimentoExameMedico/pesquisarFuncionario", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_PREVISAO_PROCEDIMENTOS = {url: BASEURL + "atendimentoExameMedico/previsaoProcedimentos", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_PROCESSAR_PREVISAO = {url: BASEURL + "atendimentoExameMedico/processarPrevisao", method: "POST"};
export const ENDPOINT_ATENDIMENTO_EXAME_MEDICO_SEGUNDA_VIA = {url: BASEURL + "atendimentoExameMedico/segundaVia", method: "POST"};

export const ENDPOINT_ATENDIMENTO_HISTORICO_LISTAR_AVALIACOES = {url: BASEURL + "atendimentoHistorico/listarAvaliacoes", method: "POST"};
export const ENDPOINT_ATENDIMENTO_HISTORICO_EXCLUIR_EX_COMPLEMENTARES = {url: BASEURL + "atendimentoHistorico/excluirExameComplementar", method: "DELETE"};
export const ENDPOINT_ATENDIMENTO_HISTORICO_GET_AVALIACAO_CLINICA = {url: BASEURL + "atendimentoHistorico/getAvaliacaoClinica", method: "POST"};
export const ENDPOINT_ATENDIMENTO_HISTORICO_GET_FUNCOES = {url: BASEURL + "atendimentoHistorico/getFuncoes", method: "POST"};
export const ENDPOINT_ATENDIMENTO_HISTORICO_GET_MEDICOS_ATIVOS = {url: BASEURL + "atendimentoHistorico/getMedicosAtivos", method: "POST"};
export const ENDPOINT_ATENDIMENTO_HISTORICO_GRAVAR_AVALIACAO_CLINICA = {url: BASEURL + "atendimentoHistorico/gravarAvaliacaoClinica", method: "POST"};
export const ENDPOINT_ATENDIMENTO_HISTORICO_INCLUIR_AC = {url: BASEURL + "atendimentoHistorico/incluirAvaliacaoClinica", method: "POST"};

export const ENDPOINT_AUDITORIAS_PESQUISAR = {url: BASEURL + "owner/auditorias/pesquisar", method: "POST"};
export const ENDPOINT_AUDITORIAS_INCLUIR = {url: BASEURL + "auditorias/incluir", method: "POST"};

export const ENDPOINT_BANCOS_ATUALIZAR = {url: BASEURL + "bancos/atualizar", method: "PUT"};
export const ENDPOINT_BANCOS_PESQUISAR = {url: BASEURL + "bancos/pesquisar", method: "POST"};
export const ENDPOINT_BANCOS_VISUALIZAR = {url: BASEURL + "bancos/visualizar", method: "POST"};

export const ENDPOINT_BOLETOS_PESQUISAR = {url: BASEURL + "boletos/pesquisar", method: "POST"};
export const ENDPOINT_BOLETOS_VISUALIZAR = {url: BASEURL + "boletos/visualizar", method: "POST"};

export const ENDPOINT_CAIXA_GERENCIA_CONTA_ATUALIZAR = {url: BASEURL + "caixaGerenciaConta/atualizar", method: "PUT"};
export const ENDPOINT_CAIXA_GERENCIA_CONTA_EXCLUIR = {url: BASEURL + "caixaGerenciaConta/excluir", method: "DELETE"};
export const ENDPOINT_CAIXA_GERENCIA_CONTA_INCLUIR = {url: BASEURL + "caixaGerenciaConta/incluir", method: "POST"};
export const ENDPOINT_CAIXA_GERENCIA_CONTA_PESQUISAR = {url: BASEURL + "caixaGerenciaConta/pesquisar", method: "POST"};
export const ENDPOINT_CAIXA_GERENCIA_CONTA_VISUALIZAR = {url: BASEURL + "caixaGerenciaConta/visualizar", method: "POST"};

export const ENDPOINT_CAIXA_GERENCIA_EXTRATO_ABRIR_MOVIMENTO = {url: BASEURL + "caixaGerenciaExtrato/abrirMovimento", method: "PUT"};
export const ENDPOINT_CAIXA_GERENCIA_EXTRATO_ATUALIZAR = {url: BASEURL + "caixaGerenciaExtrato/atualizar", method: "PUT"};
export const ENDPOINT_CAIXA_GERENCIA_EXTRATO_CANCELAR = {url: BASEURL + "caixaGerenciaExtrato/cancelar", method: "PUT"};
export const ENDPOINT_CAIXA_GERENCIA_EXTRATO_INCLUIR = {url: BASEURL + "caixaGerenciaExtrato/incluir", method: "POST"};
export const ENDPOINT_CAIXA_GERENCIA_EXTRATO_LISTAR_PREVIA = {url: BASEURL + "caixaGerenciaExtrato/listarPrevia", method: "POST"};
export const ENDPOINT_CAIXA_GERENCIA_EXTRATO_PESQUISAR = {url: BASEURL + "caixaGerenciaExtrato/pesquisar", method: "POST"};
export const ENDPOINT_CAIXA_GERENCIA_EXTRATO_RELATORIO = {url: BASEURL + "caixaGerenciaExtrato/relatorio", method: "POST"};
export const ENDPOINT_CAIXA_GERENCIA_EXTRATO_RELATORIO_PREVISAO = {url: BASEURL + "caixaGerenciaExtrato/relatorioPrevisao", method: "POST"};
export const ENDPOINT_CAIXA_GERENCIA_EXTRATO_VISUALIZAR = {url: BASEURL + "caixaGerenciaExtrato/visualizar", method: "POST"};

export const ENDPOINT_CAIXA_GERENCIA_MOVIMENTO_ATUALIZAR = {url: BASEURL + "caixaGerenciaMovimento/atualizar", method: "PUT"};
export const ENDPOINT_CAIXA_GERENCIA_MOVIMENTO_EXCLUIR = {url: BASEURL + "caixaGerenciaMovimento/excluir", method: "DELETE"};
export const ENDPOINT_CAIXA_GERENCIA_MOVIMENTO_INCLUIR = {url: BASEURL + "caixaGerenciaMovimento/incluir", method: "POST"};
export const ENDPOINT_CAIXA_GERENCIA_MOVIMENTO_PESQUISAR = {url: BASEURL + "caixaGerenciaMovimento/pesquisar", method: "POST"};
export const ENDPOINT_CAIXA_GERENCIA_MOVIMENTO_VISUALIZAR = {url: BASEURL + "caixaGerenciaMovimento/visualizar", method: "POST"};

export const ENDPOINT_CAIXA_RECEPCAO_ABRIR = {url: BASEURL + "caixaRecepcao/abrir", method: "POST"};
export const ENDPOINT_CAIXA_RECEPCAO_FECHAR = {url: BASEURL + "caixaRecepcao/fechar", method: "POST"};
export const ENDPOINT_CAIXA_RECEPCAO_LISTAR_POR_FILIAL = {url: BASEURL + "caixaRecepcao/listarCaixasPorFlial", method: "POST"};
export const ENDPOINT_CAIXA_RECEPCAO_PESQUISAR = {url: BASEURL + "caixaRecepcao/pesquisar", method: "POST"};
export const ENDPOINT_CAIXA_RECEPCAO_REL_FECHAMENTO = {url: BASEURL + "caixaRecepcao/relatorioFechamento", method: "POST"};
export const ENDPOINT_CAIXA_RECEPCAO_VISUALIZAR = {url: BASEURL + "caixaRecepcao/visualizar", method: "POST"};

export const ENDPOINT_CAPA_PESQUISAR = {url: BASEURL + "capas/pesquisar", method: "POST"};

export const ENDPOINT_CBO_LISTAR = {url: BASEURL + "cbos/listar", method: "GET"};
export const ENDPOINT_CBO_PESQUISAR = {url: BASEURL + "cbos/pesquisar", method: "POST"};

export const ENDPOINT_CIDADES_ATUALIZAR = {url: BASEURL + "cidades/atualizar", method: "PUT"};
export const ENDPOINT_CIDADES_EXCLUIR = {url: BASEURL + "cidades/excluir", method: "DELETE"};
export const ENDPOINT_CIDADES_INCLUIR = {url: BASEURL + "cidades/incluir", method: "POST"};
export const ENDPOINT_CIDADES_LISTAR = BASEURL + "cidades/listar";
export const ENDPOINT_CIDADES_LISTAR_BAIRROS = BASEURL + "cidades/listarBairros";
export const ENDPOINT_CIDADES_PESQUISAR = {url: BASEURL + "cidades/pesquisar", method: "POST"};
export const ENDPOINT_CIDADES_VISUALIZAR = {url: BASEURL + "cidades/visualizar", method: "POST"};

export const ENDPOINT_CNAE_LISTAR = {url: BASEURL + "cnaes/listar", method: "GET"};
export const ENDPOINT_CNAE_PESQUISAR = {url: BASEURL + "cnaes/pesquisar", method: "POST"};

export const ENDPOINT_COBRANCA_AGENDAR_ENVIOS = {url: BASEURL + "cobranca/agendarEnvios", method: "POST"};
export const ENDPOINT_COBRANCA_ENVIAR_POR_EMPRESA = {url: BASEURL + "cobranca/envioPorEmpresa", method: "POST"};

export const ENDPOINT_CONSULTORIO_AGENDA_ALTERAR_SITUACAO = {url: BASEURL + "consultorio/agenda/alterarSituacaoAgenda", method: "POST"};
export const ENDPOINT_CONSULTORIO_AGENDA_GET_DADOS_EMPRESA = {url: BASEURL + "consultorio/agenda/getDadosEmpresa", method: "POST"};
export const ENDPOINT_CONSULTORIO_AGENDA_LISTAR = {url: BASEURL + "consultorio/agenda/listar", method: "POST"};
export const ENDPOINT_CONSULTORIO_ANALISE_EXCLUIR = {url: BASEURL + "consultorio/analise/excluir", method: "DELETE"};
export const ENDPOINT_CONSULTORIO_ANALISE_LISTAR = {url: BASEURL + "consultorio/analise/listar", method: "POST"};
export const ENDPOINT_CONSULTORIO_ANALISE_RESOLVER = {url: BASEURL + "consultorio/analise/resolver", method: "POST"};
export const ENDPOINT_CONSULTORIO_ANALISE_SOLICITAR = {url: BASEURL + "consultorio/analise/solicitar", method: "POST"};
export const ENDPOINT_CONSULTORIO_EXPORTAR_PRONTUARIOS = {url: BASEURL + "consultorio/prontuario/exportar", method: "POST"};
export const ENDPOINT_CONSULTORIO_FUNCIONARIOS_GET = {url: BASEURL + "consultorio/funcionarios/get", method: "POST"};
export const ENDPOINT_CONSULTORIO_FUNCIONARIOS_PESQUISAR = {url: BASEURL + "consultorio/funcionarios/pesquisar", method: "POST"};
export const ENDPOINT_CONSULTORIO_HISTORICO_LISTAR_AVALIACOES = {url: BASEURL + "consultorio/historico/listarAvaliacoes", method: "POST"};
export const ENDPOINT_CONSULTORIO_HISTORICO_NOVO_ATENDIMENTO = {url: BASEURL + "consultorio/historico/novoAtendimento", method: "POST"};
export const ENDPOINT_CONSULTORIO_PRONTUARIO_ANALISAR = {url: BASEURL + "consultorio/prontuario/analisar", method: "POST"};
export const ENDPOINT_CONSULTORIO_PRONTUARIO_CONCLUIR = {url: BASEURL + "consultorio/prontuario/concluir", method: "POST"};
export const ENDPOINT_CONSULTORIO_PRONTUARIO_CONTINUAR = {url: BASEURL + "consultorio/prontuario/continuar", method: "POST"};
export const ENDPOINT_CONSULTORIO_PRONTUARIO_EDITAR = {url: BASEURL + "consultorio/prontuario/editar", method: "POST"};
export const ENDPOINT_CONSULTORIO_PRONTUARIO_EXCLUIR = {url: BASEURL + "consultorio/prontuario/excluir", method: "DELETE"};
export const ENDPOINT_CONSULTORIO_PRONTUARIO_GRAVAR = {url: BASEURL + "consultorio/prontuario/gravar", method: "PUT"};
export const ENDPOINT_CONSULTORIO_PRONTUARIO_INICIAR = {url: BASEURL + "consultorio/prontuario/iniciar", method: "POST"};
export const ENDPOINT_CONSULTORIO_RECEITAS_ATUALIZAR = {url: BASEURL + "consultorio/receitas/atualizar", method: "PUT"};
export const ENDPOINT_CONSULTORIO_RECEITAS_EXCLUIR = {url: BASEURL + "consultorio/receitas/excluir", method: "DELETE"};
export const ENDPOINT_CONSULTORIO_RECEITAS_GERAR_PDF = {url: BASEURL + "consultorio/receitas/gerarPdf", method: "POST"};
export const ENDPOINT_CONSULTORIO_RECEITAS_INCLUIR = {url: BASEURL + "consultorio/receitas/incluir", method: "POST"};
export const ENDPOINT_CONSULTORIO_RECEITAS_LISTAR = {url: BASEURL + "consultorio/receitas/listar", method: "GET"};
export const ENDPOINT_CONSULTORIO_RECEITAS_PESQUISAR = {url: BASEURL + "consultorio/receitas/pesquisar", method: "POST"};
export const ENDPOINT_CONSULTORIO_RECEITAS_RECUPERAR_TEXTO_COM_MODIFICADORES = {url: BASEURL + "consultorio/receitas/recuperarTextoComModificadores", method: "POST"};
export const ENDPOINT_CONSULTORIO_RECEITAS_VISUALIZAR = {url: BASEURL + "consultorio/receitas/visualizar", method: "POST"};

export const ENDPOINT_CONTAS_ATUALIZAR = {url: BASEURL + "contas/atualizar", method: "PUT"};
export const ENDPOINT_CONTAS_EXCLUIR = {url: BASEURL + "contas/excluir", method: "DELETE"};
export const ENDPOINT_CONTAS_INCLUIR = {url: BASEURL + "contas/incluir", method: "POST"};
export const ENDPOINT_CONTAS_LISTAR_POR_FILIAL = {url: BASEURL + "contas/listarContasPorFilial", method: "GET"};
export const ENDPOINT_CONTAS_LISTAR_ATIVAS_POR_FILIAL = {url: BASEURL + "contas/listarContasAtivasPorFilial", method: "GET"};
export const ENDPOINT_CONTAS_PESQUISAR = {url: BASEURL + "contas/pesquisar", method: "POST"};
export const ENDPOINT_CONTAS_VISUALIZAR = {url: BASEURL + "contas/visualizar", method: "POST"};

export const ENDPOINT_CONTRATOS_ATUALIZAR = {url: BASEURL + "contratos/atualizar", method: "PUT"};
//export const ENDPOINT_CONTRATOS_ENUMS = {url: BASEURL + "contratos/enums", method: "GET"};
export const ENDPOINT_CONTRATOS_GERAR = {url: BASEURL + "contratos/gerar", method: "POST"};
export const ENDPOINT_CONTRATOS_INCLUIR = {url: BASEURL + "contratos/incluir", method: "POST"};
export const ENDPOINT_CONTRATOS_PESQUISAR = {url: BASEURL + "contratos/pesquisar", method: "POST"};
export const ENDPOINT_CONTRATOS_EXCLUIR = {url: BASEURL + "contratos/excluir", method: "DELETE"};
export const ENDPOINT_CONTRATOS_VISUALIZAR = {url: BASEURL + "contratos/visualizar", method: "POST"};

//export const ENDPOINT_CONVOCACAO_EX_PERIODICOS_DISPARAR_EMAIL = {url: BASEURL + "convocacaoExPeriodicos/dispararEmail", method: "POST"};
export const ENDPOINT_CONVOCACAO_EX_PERIODICOS_ENUMS = {url: BASEURL + "convocacaoExPeriodicos/enums", method: "GET"};
export const ENDPOINT_CONVOCACAO_EX_AGENDAR_ENVIOS = {url: BASEURL + "convocacaoExPeriodicos/agendarEnvios", method: "POST"};
export const ENDPOINT_CONVOCACAO_EX_PERIODICOS_RELATORIO = {url: BASEURL + "convocacaoExPeriodicos/relatorio", method: "POST"};
export const ENDPOINT_CONVOCACAO_EX_PERIODICOS_PLANILHA = {url: BASEURL + "convocacaoExPeriodicos/planilha", method: "POST"};

export const ENDPOINT_CREDENCIADO_FILIAL_ATUALIZAR = {url: BASEURL + "credenciadoFilial/atualizar", method: "PUT"};
export const ENDPOINT_CREDENCIADO_FILIAL_EXCLUIR = {url: BASEURL + "credenciadoFilial/excluir", method: "DELETE"};
export const ENDPOINT_CREDENCIADO_FILIAL_INCLUIR = {url: BASEURL + "credenciadoFilial/incluir", method: "POST"};
export const ENDPOINT_CREDENCIADO_FILIAL_PESQUISAR = {url: BASEURL + "credenciadoFilial/pesquisar", method: "POST"};
export const ENDPOINT_CREDENCIADO_FILIAL_VISUALIZAR = {url: BASEURL + "credenciadoFilial/visualizar", method: "POST"};

export const ENDPOINT_CREDENCIADO_PROCEDIMENTOS_GRID = {url: BASEURL + "credenciados/gridProcedimentos", method: "POST"};
export const ENDPOINT_CREDENCIADO_PROCEDIMENTOS_EXCLUIR = {url: BASEURL + "credenciados/excluirProcedimento", method: "DELETE"};
export const ENDPOINT_CREDENCIADO_PROCEDIMENTOS_VISUALIZAR = {url: BASEURL + "credenciados/visualizarProcedimento", method: "POST"};
export const ENDPOINT_CREDENCIADO_PROCEDIMENTOS_GRAVAR = {url: BASEURL + "credenciados/gravarProcedimento", method: "POST"};

export const ENDPOINT_CREDENCIADOS_ATUALIZAR = {url: BASEURL + "credenciados/atualizar", method: "PUT"};
export const ENDPOINT_CREDENCIADOS_AUTOCOMPLETE = {url: BASEURL + "credenciados/autocomplete", method: "POST"};
export const ENDPOINT_CREDENCIADOS_INCLUIR = {url: BASEURL + "credenciados/incluir", method: "POST"};
export const ENDPOINT_CREDENCIADOS_RELATORIO = {url: BASEURL + "credenciados/relatorio", method: "POST"};
export const ENDPOINT_CREDENCIADOS_VISUALIZAR = {url: BASEURL + "credenciados/visualizar", method: "GET"}; 

export const ENDPOINT_DOCUMENTOS_PESQUISAR = {url: BASEURL + "documentos/pesquisar", method: "POST"};

export const ENDPOINT_DOWNLOAD_FILE = BASEURL + "file/download"; 
export const ENDPOINT_DOWNLOAD_ARQUIVO_REMESSA = {url: BASEURL + "file/downloadArquivoRemessa", method: "GET"};
export const ENDPOINT_DOWNLOAD_ARQUIVO_NFSE = {url: BASEURL + "file/downloadArquivoNfse", method: "GET"};
export const ENDPOINT_EXCLUIR_ARQUIVO_REMESSA = {url: BASEURL + "file/excluirArquivoRemessa", method: "POST"};
export const ENDPOINT_EXCLUIR_ARQUIVO_NFSE = {url: BASEURL + "file/excluirArquivoNfse", method: "POST"};

export const ENDPOINT_EMPRESAS_ATUALIZAR = BASEURL + "empresas";
export const ENDPOINT_EMPRESAS_ATUALIZAR_SERV_ONLINE = BASEURL + "empresas/atualizarServicosOnLine";
export const ENDPOINT_EMPRESAS_AUTOCOMPLETE = BASEURL + "empresas/autocomplete";
export const ENDPOINT_EMPRESAS_ENUMS = {url: BASEURL + "empresas/enums", method: "GET"};
export const ENDPOINT_EMPRESAS_EXCLUIR = BASEURL + "empresas";
export const ENDPOINT_EMPRESAS_GRAVAR_OBSERVACOES_GERAIS = {url: BASEURL + "empresas/gravarObservacoesGerais", method: "POST"}; 
export const ENDPOINT_EMPRESAS_INCLUIR = BASEURL + "empresas";
export const ENDPOINT_EMPRESAS_LISTA = BASEURL + "empresas";
export const ENDPOINT_EMPRESAS_LISTAR_FUNCIONARIOS_ATIVOS = {url: BASEURL + "empresas/listarFuncionariosAtivos", method: "POST"}; 
export const ENDPOINT_EMPRESAS_ORGANOGRAMA_PDF = {url: BASEURL + "empresas/organogramaPdf", method: "POST"}; 
export const ENDPOINT_EMPRESAS_ORGANOGRAMA_POR_EMAIL = {url: BASEURL + "empresas/organogramaPorEmail", method: "POST"}; 
export const ENDPOINT_EMPRESAS_PESQUISAR = {url: BASEURL + "empresas/pesquisar", method: "POST"};
export const ENDPOINT_EMPRESAS_SET_INFORMACOES_FINANCEIRAS = {url: BASEURL + "empresas/setInformacoesFinanceiras", method: "POST"}; 
export const ENDPOINT_EMPRESAS_SIT_EXAMES_MEDICOS_PDF = {url: BASEURL + "empresas/situacaoExamesMedicosPdf", method: "POST"}; 
export const ENDPOINT_EMPRESAS_SIT_EXAMES_MEDICOS_POR_EMAIL = {url: BASEURL + "empresas/situacaoExamesMedicosPorEmail", method: "POST"}; 
export const ENDPOINT_EMPRESAS_VISUALIZAR = BASEURL + "empresas";

export const ENDPOINT_EMPRESA_DADOS_PGR_VISUALIZAR = {url: BASEURL + "empresaDadosPgr", method: "GET"};
export const ENDPOINT_EMPRESA_DADOS_PGR_ATUALIZAR = {url: BASEURL + "empresaDadosPgr", method: "PUT"};

export const ENDPOINT_EMPRESA_EQUIPAMENTO_INDEX = {url: BASEURL + "empresaEquipamento", method: "GET"};
export const ENDPOINT_EMPRESA_EQUIPAMENTO_INCLUIR = {url: BASEURL + "empresaEquipamento", method: "POST"};
export const ENDPOINT_EMPRESA_EQUIPAMENTO_EXCLUIR = {url: BASEURL + "empresaEquipamento", method: "DELETE"};

export const ENDPOINT_EMPRESA_FATURA_ATUALIZAR = {url: BASEURL + "empresaFatura/atualizar", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_CANCELAR = {url: BASEURL + "empresaFatura/cancelar", method: "POST"};
//export const ENDPOINT_EMPRESA_FATURA_GERAR_NOTA = {url: BASEURL + "empresaFatura/gerarNota", method: "POST"};
//export const ENDPOINT_EMPRESA_FATURA_GERAR_ARQUIVO_REMESSA = {url: BASEURL + "empresaFatura/gerarRemessa", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_INCLUIR = {url: BASEURL + "empresaFatura/incluir", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_LISTAR_ABERTAS = {url: BASEURL + "empresaFatura/listarAbertas", method: "POST"};
//export const ENDPOINT_EMPRESA_FATURA_LISTAR_ARQUIVOS_REMESSA = {url: BASEURL + "empresaFatura/listarArquivosRemessa", method: "GET"};
//export const ENDPOINT_EMPRESA_FATURA_LISTAR_ARQUIVOS_NFSE = {url: BASEURL + "empresaFatura/listarArquivosNfse", method: "GET"};
export const ENDPOINT_EMPRESA_FATURA_LISTAR_POR_EMPRESA = {url: BASEURL + "empresaFatura/listarPorEmpresa", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_PESQUISAR = {url: BASEURL + "empresaFatura/pesquisar", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_VISUALIZAR = {url: BASEURL + "empresaFatura/visualizar", method: "POST"};

export const ENDPOINT_EMPRESA_FATURA_PAGAMENTO_ATUALIZAR = {url: BASEURL + "empresaFaturaPagamento/atualizar", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_PAGAMENTO_EXCLUIR = {url: BASEURL + "empresaFaturaPagamento/excluir", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_PAGAMENTO_ESTORNAR = {url: BASEURL + "empresaFaturaPagamento/estornar", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_PAGAMENTO_INLCUIR = {url: BASEURL + "empresaFaturaPagamento/incluir", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_PAGAMENTO_INLCUIR_RECEBIMENTO_CAIXA_RECEPCAO = {url: BASEURL + "empresaFaturaPagamento/incluirRecebimentoCaixaRecepcao", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_PAGAMENTO_LISTAR_RECEBIMENTOS_CAIXA_RECEPCAO = {url: BASEURL + "empresaFaturaPagamento/listarRecebimentosCaixaRecepcao", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_PAGAMENTO_RECIBO = {url: BASEURL + "empresaFaturaPagamento/recibo", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_PAGAMENTO_VISUALIZAR = {url: BASEURL + "empresaFaturaPagamento/visualizar", method: "POST"};

export const ENDPOINT_EMPRESA_FATURA_SERVICO_ATUALIZAR = {url: BASEURL + "empresaFaturaServico/atualizar", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_SERVICO_EXCLUIR = {url: BASEURL + "empresaFaturaServico/excluir", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_SERVICO_INLCUIR = {url: BASEURL + "empresaFaturaServico/incluir", method: "POST"};
export const ENDPOINT_EMPRESA_FATURA_SERVICO_VISUALIZAR = {url: BASEURL + "empresaFaturaServico/visualizar", method: "POST"};

export const ENDPOINT_EMPRESA_FUNCAO_ATUALIZAR = BASEURL + "empresaFuncao";
export const ENDPOINT_EMPRESA_FUNCAO_AUTOCOMPLETE = {url: BASEURL + "empresaFuncao/autocomplete", method: "POST"};
export const ENDPOINT_EMPRESA_FUNCAO_EXCLUIR = BASEURL + "empresaFuncao";
export const ENDPOINT_EMPRESA_FUNCAO_IMPORTAR_DADOS = BASEURL + "empresaFuncao/importarDados";
export const ENDPOINT_EMPRESA_FUNCAO_INCLUIR = BASEURL + "empresaFuncao";
export const ENDPOINT_EMPRESA_FUNCAO_INDEX = BASEURL + "empresaFuncao";
export const ENDPOINT_EMPRESA_FUNCAO_LISTAR_ATIVAS_POR_EMPRESA = BASEURL + "empresaFuncao/listarAtivasPorEmpresa";
export const ENDPOINT_EMPRESA_FUNCAO_LISTAR_TODAS_POR_EMPRESA = BASEURL + "empresaFuncao/listarTodasPorEmpresa";
export const ENDPOINT_EMPRESA_FUNCAO_PESQUISAR = {url: BASEURL + "empresaFuncao/pesquisar", method: "POST"};
export const ENDPOINT_EMPRESA_FUNCAO_VISUALIZAR = BASEURL + "empresaFuncao";

export const ENDPOINT_EMPRESA_FUNCAO_MEDIDAS_EPC_LISTAR = BASEURL + "empresaFuncaoMedidasEpc/listar";
export const ENDPOINT_EMPRESA_FUNCAO_MEDIDAS_EPC_INCLUIR = BASEURL + "empresaFuncaoMedidasEpc";
export const ENDPOINT_EMPRESA_FUNCAO_MEDIDAS_EPC_EXCLUIR = BASEURL + "empresaFuncaoMedidasEpc";

export const ENDPOINT_EMPRESA_FUNCAO_MEDIDAS_EPI_LISTAR = BASEURL + "empresaFuncaoMedidasEpi/listar";
export const ENDPOINT_EMPRESA_FUNCAO_MEDIDAS_EPI_INCLUIR = BASEURL + "empresaFuncaoMedidasEpi";
export const ENDPOINT_EMPRESA_FUNCAO_MEDIDAS_EPI_EXCLUIR = BASEURL + "empresaFuncaoMedidasEpi";
export const ENDPOINT_EMPRESA_FUNCAO_MEDIDAS_EPI_ATUALIZAR = BASEURL + "empresaFuncaoMedidasEpi";
export const ENDPOINT_EMPRESA_FUNCAO_MEDIDAS_EPI_VISUALIZAR = BASEURL + "empresaFuncaoMedidasEpi";

//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_LISTAR = BASEURL + "empresaFuncaoRisco/listar";
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_EXCLUIR = BASEURL + "empresaFuncaoRisco/excluir";
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_EXCLUIR_TUDO = {url: BASEURL + "empresaFuncaoRisco/excluirTudo", method: "DELETE"};
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_VISUALIZAR = BASEURL + "empresaFuncaoRisco/visualizar";
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_COPIAR_GRUPO = {url: BASEURL + "empresaFuncaoRisco/copiarGrupo", method: "POST"};
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_INCLUIR = BASEURL + "empresaFuncaoRisco/incluir";
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_ATUALIZAR = BASEURL + "empresaFuncaoRisco/atualizar";
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_ENUMS = BASEURL + "empresaFuncaoRisco/enums";

//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_EPC_ATUALIZAR = {url: BASEURL + "empresaFuncaoRiscoEpc/atualizar", method: "POST"};
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_EPC_INCLUIR = {url: BASEURL + "empresaFuncaoRiscoEpc", method: "POST"};
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_EPC_EXCLUIR = {url: BASEURL + "empresaFuncaoRiscoEpc", method: "DELETE"};

//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_EPI_ATUALIZAR = {url: BASEURL + "empresaFuncaoRiscoEpi/atualizar", method: "POST"};
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_EPI_INCLUIR = BASEURL + "empresaFuncaoRiscoEpi";
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_EPI_EXCLUIR = BASEURL + "empresaFuncaoRiscoEpi";

//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_PLANO_ACAO_ATUALIZAR = {url: BASEURL + "empresaFuncaoRiscoPlanoAcao/atualizar", method: "PUT"};
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_PLANO_ACAO_INCLUIR = {url: BASEURL + "empresaFuncaoRiscoPlanoAcao/incluir", method: "POST"};
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_PLANO_ACAO_EXCLUIR = {url: BASEURL + "empresaFuncaoRiscoPlanoAcao/excluir", method: "DELETE"};

//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_PROPAGACAO_INCLUIR = BASEURL + "empresaFuncaoRiscoPropagacao";
//export const ENDPOINT_EMPRESA_FUNCAO_RISCO_PROPAGACAO_EXCLUIR = BASEURL + "empresaFuncaoRiscoPropagacao";

export const ENDPOINT_EMPRESA_FUNCIONARIO_ATUALIZAR = BASEURL + "funcionarios";
export const ENDPOINT_EMPRESA_FUNCIONARIO_AUTOCOMPLETE = {url: BASEURL + "funcionarios/autocomplete", method: "POST"};
export const ENDPOINT_EMPRESA_FUNCIONARIO_ENUMS = BASEURL + "funcionarios/enums";
export const ENDPOINT_EMPRESA_FUNCIONARIO_EXCLUIR = BASEURL + "funcionarios";
export const ENDPOINT_EMPRESA_FUNCIONARIO_INCLUIR = BASEURL + "funcionarios";
export const ENDPOINT_EMPRESA_FUNCIONARIO_INDEX = BASEURL + "funcionarios";
export const ENDPOINT_EMPRESA_FUNCIONARIO_PESQUISAR = {url: BASEURL + "funcionarios/pesquisar", method: "POST"};
export const ENDPOINT_EMPRESA_FUNCIONARIO_VISUALIZAR = BASEURL + "funcionarios";

export const ENDPOINT_EMPRESA_FUNCIONARIO_DEPENDENTE_LISTAR = BASEURL + "funcionarioDependente/listar";
export const ENDPOINT_EMPRESA_FUNCIONARIO_DEPENDENTE_VISUALIZAR = BASEURL + "funcionarioDependente";
export const ENDPOINT_EMPRESA_FUNCIONARIO_DEPENDENTE_INCLUIR = BASEURL + "funcionarioDependente";
export const ENDPOINT_EMPRESA_FUNCIONARIO_DEPENDENTE_ATUALIZAR = BASEURL + "funcionarioDependente";
export const ENDPOINT_EMPRESA_FUNCIONARIO_DEPENDENTE_EXCLUIR = BASEURL + "funcionarioDependente";
export const ENDPOINT_EMPRESA_FUNCIONARIO_DEPENDENTE_ENUMS = BASEURL + "funcionarioDependente/enums";

export const ENDPOINT_EMPRESA_FUNCIONARIO_EXAME_MEDICO_LISTAR = BASEURL + "funcionarioExameMedico/listar";
export const ENDPOINT_EMPRESA_FUNCIONARIO_EXAME_MEDICO_VISUALIZAR = {url: BASEURL + "funcionarioExameMedico/visualizar", method: "POST"};;
export const ENDPOINT_EMPRESA_FUNCIONARIO_EXAME_MEDICO_INCLUIR = {url: BASEURL + "funcionarioExameMedico", method: "POST"};
export const ENDPOINT_EMPRESA_FUNCIONARIO_EXAME_MEDICO_EXCLUIR = {url: BASEURL + "funcionarioExameMedico", method: "DELETE"};
export const ENDPOINT_EMPRESA_FUNCIONARIO_EXAME_MEDICO_ATUALIZAR = {url: BASEURL + "funcionarioExameMedico", method: "PUT"};

export const ENDPOINT_EMPRESA_PGR_PLANO_ACAO_LISTAR = {url: BASEURL + "empresaPgrPlanoAcao/listarPlanosAcao", method: "GET"};
export const ENDPOINT_EMPRESA_PGR_PLANO_ACAO_SINCRONIZAR = {url: BASEURL + "empresaPgrPlanoAcao/sincronizar", method: "POST"};

export const ENDPOINT_EMPRESA_PLANO_ENUMS = {url: BASEURL + "empresaPlano/enums", method: "GET"};
export const ENDPOINT_EMPRESA_PLANO_INDEX = BASEURL + "empresaPlano";
export const ENDPOINT_EMPRESA_PLANO_INCLUIR = BASEURL + "empresaPlano";
export const ENDPOINT_EMPRESA_PLANO_EXCLUIR = BASEURL + "empresaPlano";
export const ENDPOINT_EMPRESA_PLANO_VISUALIZAR = BASEURL + "empresaPlano";
export const ENDPOINT_EMPRESA_PLANO_ATUALIZAR = BASEURL + "empresaPlano";

export const ENDPOINT_EMPRESA_PLANO_ACAO_INDIVIDUALIZADO_ATUALIZAR = {url: BASEURL + "empresaPlanoAcaoIndividualizado", method: "PUT"};
export const ENDPOINT_EMPRESA_PLANO_ACAO_INDIVIDUALIZADO_INCLUIR = {url: BASEURL + "empresaPlanoAcaoIndividualizado", method: "POST"};
export const ENDPOINT_EMPRESA_PLANO_ACAO_INDIVIDUALIZADO_LISTAR = {url: BASEURL + "empresaPlanoAcaoIndividualizado", method: "GET"};
export const ENDPOINT_EMPRESA_PLANO_ACAO_INDIVIDUALIZADO_EXCLUIR = {url: BASEURL + "empresaPlanoAcaoIndividualizado", method: "DELETE"};

export const ENDPOINT_EMPRESA_PLANO_SERVICO_INCLUIR = BASEURL + "empresaPlanoServico";
export const ENDPOINT_EMPRESA_PLANO_SERVICO_EXCLUIR = BASEURL + "empresaPlanoServico";

export const ENDPOINT_EMPRESA_PRECO_ESPECIAL_GRAVAR_LISTA = {url: BASEURL + "empresaPrecoEspecial/gravarLista", method: "PUT"};
export const ENDPOINT_EMPRESA_PRECO_ESPECIAL_LISTAR = {url: BASEURL + "empresaPrecoEspecial/listar", method: "POST"};

export const ENDPOINT_EMPRESA_RESP_REG_AMBIENTAL_LISTAR_PROFISSIONAIS = {url: BASEURL + "empresaRespRegAmbiental/listarProfissionais", method: "GET"};
export const ENDPOINT_EMPRESA_RESP_REG_AMBIENTAL_INDEX = {url: BASEURL + "empresaRespRegAmbiental", method: "GET"};
export const ENDPOINT_EMPRESA_RESP_REG_AMBIENTAL_INCLUIR = {url: BASEURL + "empresaRespRegAmbiental", method: "POST"};
export const ENDPOINT_EMPRESA_RESP_REG_AMBIENTAL_EXCLUIR = {url: BASEURL + "empresaRespRegAmbiental", method: "DELETE"};

export const ENDPOINT_EMPRESA_SERVICO_ENUMS = {url: BASEURL + "empresaServicos/enums", method: "GET"};
export const ENDPOINT_EMPRESA_SERVICO_ATUALIZAR = {url: BASEURL + "empresaServicos", method: "PUT"};
export const ENDPOINT_EMPRESA_SERVICO_INCLUIR = {url: BASEURL + "empresaServicos", method: "POST"};
export const ENDPOINT_EMPRESA_SERVICO_LISTAR = {url: BASEURL + "empresaServicos/listar", method: "GET"};
export const ENDPOINT_EMPRESA_SERVICO_LISTAR_ATIVOS = {url: BASEURL + "empresaServicos/listarAtivos", method: "POST"};
export const ENDPOINT_EMPRESA_SERVICO_RENOVAR_SERVICO = {url: BASEURL + "empresaServicos/renovarServico", method: "GET"};
export const ENDPOINT_EMPRESA_SERVICO_EXCLUIR = {url: BASEURL + "empresaServicos", method: "DELETE"};
export const ENDPOINT_EMPRESA_SERVICO_VISUALIZAR = {url: BASEURL + "empresaServicos", method: "GET"};
export const ENDPOINT_EMPRESA_SERVICO_CANCELAR = {url: BASEURL + "empresaServicos/cancelar", method: "GET"};
export const ENDPOINT_EMPRESA_SERVICO_CONCLUIR = {url: BASEURL + "empresaServicos/concluir", method: "GET"};
export const ENDPOINT_EMPRESA_SERVICO_PROCESSAR_RENOVACAO_AUTOMATICA = {url: BASEURL + "empresaServicos/processarRenovacaoAutomatica", method: "POST"};

export const ENDPOINT_EMPRESA_SERVICO_COBERTURA_LISTAR = {url: BASEURL + "empresaServicoCoberturas/listar", method: "GET"};
export const ENDPOINT_EMPRESA_SERVICO_COBERTURA_INCLUIR = {url: BASEURL + "empresaServicoCoberturas", method: "POST"};
export const ENDPOINT_EMPRESA_SERVICO_COBERTURA_EXCLUIR = {url: BASEURL + "empresaServicoCoberturas", method: "DELETE"};

export const ENDPOINT_EMPRESA_SETOR_ATUALIZAR = BASEURL + "empresaSetor";
export const ENDPOINT_EMPRESA_SETOR_EXCLUIR = BASEURL + "empresaSetor";
export const ENDPOINT_EMPRESA_SETOR_INCLUIR = BASEURL + "empresaSetor";
export const ENDPOINT_EMPRESA_SETOR_INDEX = BASEURL + "empresaSetor";
export const ENDPOINT_EMPRESA_SETOR_LISTAR_POR_EMPRESA = BASEURL + "empresaSetor/listarPorEmpresa";
export const ENDPOINT_EMPRESA_SETOR_PESQUISAR = {url: BASEURL + "empresaSetor/pesquisar", method: "POST"};
export const ENDPOINT_EMPRESA_SETOR_VISUALIZAR = BASEURL + "empresaSetor";

export const ENDPOINT_ENCAMINHAMENTO_ESPECIALISTA_EXCLUIR = {url: BASEURL + "encaminhamentoEspecialista/excluir", method: "DELETE"};
export const ENDPOINT_ENCAMINHAMENTO_ESPECIALISTA_INCLUIR = {url: BASEURL + "encaminhamentoEspecialista/incluir", method: "POST"};
export const ENDPOINT_ENCAMINHAMENTO_ESPECIALISTA_LISTAR = {url: BASEURL + "encaminhamentoEspecialista/listar", method: "POST"};

export const ENDPOINT_ENTREGA_DOCUMENTO_ENUMS = {url: BASEURL + "entregaDocumento/enums", method: "GET"};
export const ENDPOINT_ENTREGA_DOCUMENTO_EXCLUIR = {url: BASEURL + "entregaDocumento/excluir", method: "DELETE"};
export const ENDPOINT_ENTREGA_DOCUMENTO_LOAD = {url: BASEURL + "entregaDocumento/load", method: "POST"};
export const ENDPOINT_ENTREGA_DOCUMENTO_LISTAR_POR_EMPRESA = {url: BASEURL + "entregaDocumento/listarPorEmpresa", method: "POST"};
export const ENDPOINT_ENTREGA_DOCUMENTO_SAVE = {url: BASEURL + "entregaDocumento/save", method: "POST"};

export const ENDPOINT_ENUMS_LOAD = {url: BASEURL + "enums/load", method: "POST"};

export const ENDPOINT_ESOCIAL_CADASTRAR_EMPREGADOR = {url: BASEURL + "esocial/cadastrarEmpregador", method: "GET"};
export const ENDPOINT_ESOCIAL_CSV_RECIBOS = {url: BASEURL + "esocial/planilhaRecibos", method: "POST"};
export const ENDPOINT_ESOCIAL_MONITORAMENTO = {url: BASEURL + "esocial/monitoramento", method: "GET"};
export const ENDPOINT_ESOCIAL_MONITORAMENTO_CHECK_EMPRESA = {url: BASEURL + "esocial/checkEmpresa", method: "POST"};
export const ENDPOINT_ESOCIAL_MONITORAMENTO_ENUMS = {url: BASEURL + "esocial/enums", method: "GET"};
export const ENDPOINT_ESOCIAL_MONITORAMENTO_EXPORTAR_PLANILHA = {url: BASEURL + "esocial/exportarPlanilhaMonitoramento", method: "POST"};

export const ENDPOINT_ESTORNOS_PESQUISAR = {url: BASEURL + "estornos/pesquisar", method: "POST"};
export const ENDPOINT_ESTORNOS_VISUALIZAR = {url: BASEURL + "estornos/visualizar", method: "POST"};

export const ENDPOINT_FILIAIS_ATUALIZAR = {url: BASEURL + "filiais/atualizar", method: "PUT"};
export const ENDPOINT_FILIAIS_ENUMS = {url: BASEURL + "filiais/enums", method: "GET"};
export const ENDPOINT_FILIAIS_GET_CONFIGURACOES = {url: BASEURL + "filiais/getConfiguracoes", method: "POST"};
export const ENDPOINT_FILIAIS_GET_PERIODO_FECHAMENTO_GUIAS = {url: BASEURL + "filiais/getPeriodoFechamentoGuias", method: "POST"};
export const ENDPOINT_FILIAIS_PESQUISAR = {url: BASEURL + "filiais/pesquisar", method: "POST"};
export const ENDPOINT_FILIAIS_VISUALIZAR = {url: BASEURL + "filiais/visualizar", method: "POST"};

export const ENDPOINT_GERADOR_BOLETO_ENVIAR_POR_EMAIL = {url: BASEURL + "geradorBoleto/enviarPorEmail", method: "POST"};
export const ENDPOINT_GERADOR_BOLETO_ENVIAR_EM_LOTE = {url: BASEURL + "geradorBoleto/enviarBoletoFaturaEmLote", method: "POST"};
export const ENDPOINT_GERADOR_BOLETO_VISUALIZAR = {url: BASEURL + "geradorBoleto/visualizar", method: "POST"};

export const ENDPOINT_GERAR_EFETIVAR_PREVIA_EFETIVAR = {url: BASEURL + "gerarEfetivarPrevia/efetivar", method: "POST"};
export const ENDPOINT_GERAR_EFETIVAR_PREVIA_EXCLUIR = {url: BASEURL + "gerarEfetivarPrevia/excluir", method: "DELETE"};
export const ENDPOINT_GERAR_EFETIVAR_PREVIA_EXPORTAR_CSV = {url: BASEURL + "gerarEfetivarPrevia/exportarCsv", method: "POST"};
export const ENDPOINT_GERAR_EFETIVAR_PREVIA_GERAR = {url: BASEURL + "gerarEfetivarPrevia/gerar", method: "POST"};
export const ENDPOINT_GERAR_EFETIVAR_PREVIA_LISTAR = {url: BASEURL + "gerarEfetivarPrevia/listar", method: "POST"};

export const ENDPOINT_GRUPOS_INDEX = BASEURL + "owner/grupos";
export const ENDPOINT_GRUPOS_LISTAR = BASEURL + "owner/grupos";
export const ENDPOINT_GRUPOS_VISUALIZAR = BASEURL + "owner/grupos";
export const ENDPOINT_GRUPOS_ATUALIZAR = BASEURL + "owner/grupos";

export const ENDPOINT_GRUPO_CONTA_ATUALIZAR = {url: BASEURL + "grupoContas/atualizar", method: "PUT"};
export const ENDPOINT_GRUPO_CONTA_ENUMS = {url: BASEURL + "grupoContas/enums", method: "GET"};
export const ENDPOINT_GRUPO_CONTA_EXCLUIR = {url: BASEURL + "grupoContas/excluir", method: "DELETE"};
export const ENDPOINT_GRUPO_CONTA_INCLUIR = {url: BASEURL + "grupoContas/incluir", method: "POST"};
export const ENDPOINT_GRUPO_CONTA_LISTA = {url: BASEURL + "grupoContas", method: "GET"};
export const ENDPOINT_GRUPO_CONTA_PESQUISAR = {url: BASEURL + "grupoContas/pesquisar", method: "POST"};
export const ENDPOINT_GRUPO_CONTA_VISUALIZAR = {url: BASEURL + "grupoContas/visualizar", method: "POST"};

export const ENDPOINT_GUIAS_ENUMS = {url: BASEURL + "guias/enums", method: "GET"};
export const ENDPOINT_GUIAS_INDEX = {url: BASEURL + "guias/index", method: "POST"};
export const ENDPOINT_GUIAS_VISUALIZAR = {url: BASEURL + "guias/visualizar", method: "GET"}; 
export const ENDPOINT_GUIAS_INCLUIR = {url: BASEURL + "guias/incluir", method: "POST"};
export const ENDPOINT_GUIAS_ATUALIZAR = {url: BASEURL + "guias/atualizar", method: "PUT"};
export const ENDPOINT_GUIAS_CANCELAR = {url: BASEURL + "guias/cancelar", method: "POST"};

export const ENDPOINT_LANCAMENTO_CONTA_ENUMS = {url: BASEURL + "lancamentoContas/enums", method: "GET"};
export const ENDPOINT_LANCAMENTO_CONTA_VISUALIZAR = {url: BASEURL + "lancamentoContas", method: "GET"};
export const ENDPOINT_LANCAMENTO_CONTA_INCLUIR = {url: BASEURL + "lancamentoContas", method: "POST"};
export const ENDPOINT_LANCAMENTO_CONTA_PESQUISAR = {url: BASEURL + "lancamentoContas/pesquisar", method: "POST"};
export const ENDPOINT_LANCAMENTO_CONTA_CANCELAR = {url: BASEURL + "lancamentoContas/cancelar", method: "POST"};
export const ENDPOINT_LANCAMENTO_CONTA_GRAVAR_SALDO_INICIAL = {url: BASEURL + "lancamentoContas/gravarSaldoInicial", method: "POST"};
export const ENDPOINT_LANCAMENTO_CONTA_RELATORIO = {url: BASEURL + "lancamentoContas/relatorio", method: "POST"};
export const ENDPOINT_LANCAMENTO_CONTA_ATUALIZAR = {url: BASEURL + "lancamentoContas", method: "PUT"};

export const ENDPOINT_LTCAT_ENUMS = {url: BASEURL + "ltcat/enums", method: "GET"};
export const ENDPOINT_LTCAT_GERAR_PDF = {url: BASEURL + "ltcat/gerarPdf", method: "POST"};

export const ENDPOINT_MALA_DIRETA_AGENDAR_ENVIOS = {url: BASEURL + "maladireta/agendarEnvios", method: "POST"};
export const ENDPOINT_MALA_DIRETA_ENVIAR_EMAIL = {url: BASEURL + "maladireta/enviar", method: "POST"};
export const ENDPOINT_MALA_DIRETA_ENVIAR_EMAIL_TESTE = {url: BASEURL + "maladireta/enviarTeste", method: "POST"};
export const ENDPOINT_MALA_DIRETA_UPLOAD_IMAGEM = {url: BASEURL + "maladireta/uploadImagem", method: "POST"};

export const ENDPOINT_MEDICOS_LISTAR = {url: BASEURL + "cnaes/listar", method: "GET"};

export const ENDPOINT_MENU_ENUMS = {url: BASEURL + "menu/enums", method: "GET"};
export const ENDPOINT_MENU_LISTAR_ITEM = {url: BASEURL + "menu/listarMenuItem", method: "POST"};
export const ENDPOINT_MENU_LISTAR_ITEM_SISTEMA = {url: BASEURL + "menu/listarMenuItemSistema", method: "POST"};
export const ENDPOINT_MENU_GRAVAR_ITEM = {url: BASEURL + "menu/gravarMenuItem", method: "POST"};
export const ENDPOINT_MENU_GRAVAR_ITEM_SISTEMA = {url: BASEURL + "menu/gravarMenuItemSistema", method: "POST"};
export const ENDPOINT_MENU_GRAVAR_FILIAIS = {url: BASEURL + "menu/gravarFiliais", method: "POST"};
export const ENDPOINT_MENU_CLONAR = {url: BASEURL + "menu/clonar", method: "POST"};

export const ENDPOINT_MODELO_CARTA_ATUALIZAR = {url: BASEURL + "modeloCarta/atualizar", method: "PUT"};
export const ENDPOINT_MODELO_CARTA_EXCLUIR = {url: BASEURL + "modeloCarta/excluir", method: "DELETE"};
export const ENDPOINT_MODELO_CARTA_INCLUIR = {url: BASEURL + "modeloCarta/incluir", method: "POST"};
export const ENDPOINT_MODELO_CARTA_PESQUISAR = {url: BASEURL + "modeloCarta/pesquisar", method: "POST"};
export const ENDPOINT_MODELO_CARTA_VISUALIZAR = {url: BASEURL + "modeloCarta/visualizar", method: "POST"};

export const ENDPOINT_MODELO_PCMSO_PESQUISAR = {url: BASEURL + "modelosPcmso/pesquisar", method: "POST"};

export const ENDPOINT_MOVIMENTO_MENSAL_ENUMS = {url: BASEURL + "movimentoMensal/enums", method: "GET"};
export const ENDPOINT_MOVIMENTO_MENSAL_PROXIMA_COMPETENCIA = {url: BASEURL + "movimentoMensal/proximaCompetencia", method: "POST"};
export const ENDPOINT_MOVIMENTO_MENSAL_ABRIR = {url: BASEURL + "movimentoMensal/abrir", method: "POST"};

export const ENDPOINT_PARAMETRO_GET_PARAMETROS = {url: BASEURL + "parametros/getParametros", method: "POST"};
export const ENDPOINT_PARAMETRO_GRAVAR_PARAMETROS = {url: BASEURL + "parametros/gravarParametros", method: "PUT"};
export const ENDPOINT_PARAMETRO_TEXTO_INFORMATIVO = {url: BASEURL + "parametros/textoInformativo", method: "GET"};
export const ENDPOINT_SALVAR_TEXTO_INFORMATIVO = {url: BASEURL + "parametros/salvarTextoInformativo", method: "POST"};

export const ENDPOINT_PARAMETRO_FILIAL_GET_PARAMETROS_FILIAL_LOGADA = {url: BASEURL + "parametroFilial/getParametrosFilialLogada", method: "POST"};
export const ENDPOINT_PARAMETRO_FILIAL_GET_PERCENTUAL_ISS = {url: BASEURL + "parametroFilial/getPercentualIss", method: "POST"};
export const ENDPOINT_PARAMETRO_FILIAL_GRAVAR_PARAMETROS_FILIAL_LOGADA = {url: BASEURL + "parametroFilial/gravarParametrosFilialLogada", method: "PUT"};

export const ENDPOINT_PARCEIRO_GARRA_PROCESSAR_RETORNO = {url: BASEURL + "parceiroGarra/processarArquivoRetorno", method: "POST"};

export const ENDPOINT_PERMISSOES_ESPECIAIS_INDEX = {url: BASEURL + "permissoesEspeciais/index", method: "POST"};
export const ENDPOINT_PERMISSOES_ESPECIAIS_VISUALIZAR = {url: BASEURL + "permissoesEspeciais/visualizar", method: "GET"}; 
export const ENDPOINT_PERMISSOES_ESPECIAIS_INCLUIR = {url: BASEURL + "permissoesEspeciais/incluir", method: "POST"};
export const ENDPOINT_PERMISSOES_ESPECIAIS_CANCELAR = {url: BASEURL + "permissoesEspeciais/cancelar", method: "POST"};

export const ENDPOINT_PESSOA_FISICA_PLANILHA = BASEURL + "pessoasFisicas/planilha";

export const ENDPOINT_PGR_ENUMS = {url: BASEURL + "pgr/enums", method: "GET"};
export const ENDPOINT_PGR_GERAR_PDF = {url: BASEURL + "pgr/gerarPdf", method: "POST"};
export const ENDPOINT_PGR_GERAR_HTML = BASEURL + "pgr/gerarHtml";
export const ENDPOINT_PGR_GERAR_PDF_MODIFICADO = BASEURL + "pgr/gerarPdfModificado";

export const ENDPOINT_PGR_V2_GERAR_PDF = {url: BASEURL + "pgrV2/gerarPdf", method: "POST"};

export const ENDPOINT_PGR_PLANO_ACAO_ATUALIZAR = {url: BASEURL + "pgrPlanoAcao/atualizar", method: "PUT"};
export const ENDPOINT_PGR_PLANO_ACAO_INCLUIR = {url: BASEURL + "pgrPlanoAcao/incluir", method: "POST"};
export const ENDPOINT_PGR_PLANO_ACAO_PESQUISAR = {url: BASEURL + "pgrPlanoAcao/pesquisar", method: "POST"};
export const ENDPOINT_PGR_PLANO_ACAO_EXCLUIR = {url: BASEURL + "pgrPlanoAcao/excluir", method: "DELETE"};
export const ENDPOINT_PGR_PLANO_ACAO_VISUALIZAR = {url: BASEURL + "pgrPlanoAcao/visualizar", method: "POST"};

export const ENDPOINT_PLANILHAS_GERAR = {url: BASEURL + "planilhas/gerar", method: "POST"};

export const ENDPOINT_PLANOS_AUTOCOMPLETE = BASEURL + "planos/autocomplete";
export const ENDPOINT_PLANOS_LISTAR_POR_FILIAL = BASEURL + "planos/listarPorFilial";
export const ENDPOINT_PLANOS_LISTAR_TODOS = BASEURL + "planos/listarTodos";

//export const ENDPOINT_PRE_CONTRATOS_ENUMS = {url: BASEURL + "preContratos/enums", method: "GET"};
export const ENDPOINT_PRE_CONTRATOS_INDEX = {url: BASEURL + "preContratos/index", method: "GET"};
export const ENDPOINT_PRE_CONTRATOS_VISUALIZAR = {url: BASEURL + "preContratos/visualizar", method: "POST"}; 
export const ENDPOINT_PRE_CONTRATOS_INCLUIR = {url: BASEURL + "preContratos/incluir", method: "POST"};
export const ENDPOINT_PRE_CONTRATOS_ATUALIZAR = {url: BASEURL + "preContratos/atualizar", method: "PUT"};
export const ENDPOINT_PRE_CONTRATOS_CANCELAR = {url: BASEURL + "preContratos/cancelar", method: "PUT"};
export const ENDPOINT_PRE_CONTRATOS_CONCLUIR = {url: BASEURL + "preContratos/concluir", method: "PUT"};
export const ENDPOINT_PRE_CONTRATOS_EXCLUIR = {url: BASEURL + "preContratos/excluir", method: "DELETE"};
export const ENDPOINT_PRE_CONTRATOS_PESQUISAR = {url: BASEURL + "preContratos/pesquisar", method: "POST"};

export const ENDPOINT_PRE_CONTRATOS_CONTRACT_ATUALIZAR = {url: BASEURL + "preContratosContract/atualizar", method: "PUT"};
export const ENDPOINT_PRE_CONTRATOS_CONTRACT_EXCLUIR = {url: BASEURL + "preContratosContract/excluir", method: "DELETE"};
export const ENDPOINT_PRE_CONTRATOS_CONTRACT_IMPRIMIR = {url: BASEURL + "preContratosContract/imprimir", method: "POST"};
export const ENDPOINT_PRE_CONTRATOS_CONTRACT_INCLUIR = {url: BASEURL + "preContratosContract/incluir", method: "POST"};
export const ENDPOINT_PRE_CONTRATOS_CONTRACT_LISTAR = {url: BASEURL + "preContratosContract/listar", method: "POST"};

export const ENDPOINT_PROCEDIMENTOS_ENUMS = {url: BASEURL + "procedimentos/enums", method: "GET"};
export const ENDPOINT_PROCEDIMENTOS_AUTOCOMPLETE = BASEURL + "procedimentos/autocomplete";
export const ENDPOINT_PROCEDIMENTOS_INDEX = {url: BASEURL + "procedimentos/index", method: "POST"};
export const ENDPOINT_PROCEDIMENTOS_VISUALIZAR = {url: BASEURL + "procedimentos/visualizar", method: "GET"}; 
export const ENDPOINT_PROCEDIMENTOS_INCLUIR = {url: BASEURL + "procedimentos/incluir", method: "POST"};
export const ENDPOINT_PROCEDIMENTOS_ATUALIZAR = {url: BASEURL + "procedimentos/atualizar", method: "PUT"};
export const ENDPOINT_PROCEDIMENTOS_EXCLUIR = {url: BASEURL + "procedimentos/excluir", method: "DELETE"};

export const ENDPOINT_PROFISSIONAL_FILIAL_ATUALIZAR = {url: BASEURL + "profissionalFilial/atualizar", method: "POST"}; 
export const ENDPOINT_PROFISSIONAL_FILIAL_EXCLUIR = {url: BASEURL + "profissionalFilial/excluir", method: "DELETE"}; 
export const ENDPOINT_PROFISSIONAL_FILIAL_INCLUIR = {url: BASEURL + "profissionalFilial/incluir", method: "POST"}; 
export const ENDPOINT_PROFISSIONAL_FILIAL_LISTAR = {url: BASEURL + "profissionalFilial/listar", method: "POST"}; 

export const ENDPOINT_PROFISSIONAIS_ATUALIZAR = {url: BASEURL + "profissionais", method: "PUT"}; 
export const ENDPOINT_PROFISSIONAIS_AUTOCOMPLETE = {url: BASEURL + "profissionais/autocomplete", method: "POST"};
export const ENDPOINT_PROFISSIONAIS_ENUMS = {url: BASEURL + "profissionais/enums", method: "GET"};
export const ENDPOINT_PROFISSIONAIS_EXCLUIR = {url: BASEURL + "profissionais", method: "DELETE"}; 
export const ENDPOINT_PROFISSIONAIS_INDEX = {url: BASEURL + "profissionais/index", method: "POST"};
export const ENDPOINT_PROFISSIONAIS_INCLUIR = {url: BASEURL + "profissionais", method: "POST"}; 
export const ENDPOINT_PROFISSIONAIS_LISTAR_FILIAIS_VINCULADAS = {url: BASEURL + "profissionais/listarFiliaisVinculadas", method: "POST"};
export const ENDPOINT_PROFISSIONAIS_LISTAR_MEDICOS = {url: BASEURL + "profissionais/listarMedicos", method: "GET"};
export const ENDPOINT_PROFISSIONAIS_VISUALIZAR = {url: BASEURL + "profissionais/visualizar", method: "GET"}; 

export const ENDPOINT_RECADOS_ENUMS = {url: BASEURL + "recados/enums", method: "GET"};
export const ENDPOINT_RECADOS_NAO_LIDOS = {url: BASEURL + "recados/naoLidos", method: "POST"};
export const ENDPOINT_RECADOS_RECEBIDOS = {url: BASEURL + "recados/recebidos", method: "POST"};
export const ENDPOINT_RECADOS_ENVIADOS = {url: BASEURL + "recados/enviados", method: "POST"};
export const ENDPOINT_RECADOS_ENVIAR = {url: BASEURL + "recados/enviar", method: "POST"};
export const ENDPOINT_RECADOS_MARCAR_NAO_LIDO = {url: BASEURL + "recados/marcarNaoLido", method: "POST"};
export const ENDPOINT_RECADOS_RESPONDER = {url: BASEURL + "recados/responder", method: "POST"};
export const ENDPOINT_RECADOS_EXCLUIR = {url: BASEURL + "recados/excluir", method: "DELETE"};
export const ENDPOINT_RECADOS_VISUALIZAR = {url: BASEURL + "recados/visualizar", method: "POST"}; 

export const ENDPOINT_RELATORIO_ANUAL_PDF = {url: BASEURL + "relatorioAnual/pdf", method: "POST"};

export const ENDPOINT_RELATORIO_CAIXA_ENUMS = {url: BASEURL + "relatorioCaixa/enums", method: "GET"};
export const ENDPOINT_RELATORIO_CAIXA_GERAR = {url: BASEURL + "relatorioCaixa/gerar", method: "POST"};

export const ENDPOINT_RELATORIO_FATURA_CSV = {url: BASEURL + "relatorioFatura/csv", method: "POST"};
export const ENDPOINT_RELATORIO_FATURA_PDF = {url: BASEURL + "relatorioFatura/pdf", method: "POST"};

export const ENDPOINT_RELATORIO_GUIA_CSV = {url: BASEURL + "relatorioGuia/csv", method: "POST"};
export const ENDPOINT_RELATORIO_GUIA_PDF = {url: BASEURL + "relatorioGuia/pdf", method: "POST"};

export const ENDPOINT_RELATORIO_PCMSO_PDF = {url: BASEURL + "relatorioPcmso/pdf", method: "POST"};

export const ENDPOINT_RELATORIO_VENCIMENTO_PCMSO_PGR = {url: BASEURL + "relatorios/vencimentoPcmsoPgr", method: "POST"};

export const ENDPOINT_RISCO_OCUPACIONAL_AUTOCOMPLETE = BASEURL + "riscosOcupacionais/autocomplete";
export const ENDPOINT_RISCO_OCUPACIONAL_PESQUISAR = {url: BASEURL + "riscosOcupacionais/pesquisar", method: "POST"};

export const ENDPOINT_SALDO_BANCARIO_ENUMS = {url: BASEURL + "saldoBancario/enums", method: "GET"};
export const ENDPOINT_SALDO_BANCARIO_PESQUISAR = {url: BASEURL + "saldoBancario/pesquisar", method: "POST"};
export const ENDPOINT_SALDO_BANCARIO_GRAVAR = {url: BASEURL + "saldoBancario/gravar", method: "POST"};

export const ENDPOINT_SCHEDULE_AGENDAR_ENVIO_BOLERO = {url: BASEURL + "schedule/agendarEnvioBoleto", method: "POST"};
export const ENDPOINT_SCHEDULE_EXCLUIR = {url: BASEURL + "schedule/excluir", method: "POST"};
export const ENDPOINT_SCHEDULE_LISTAR_POR_UUID_SITUACAO = {url: BASEURL + "schedule/listarPorUuidSituacao", method: "POST"};
export const ENDPOINT_SCHEDULE_LISTAR_AGENDAMENTOS_POR_DATA = {url: BASEURL + "schedule/listarAgendamentosPorData", method: "POST"};
export const ENDPOINT_SCHEDULE_RESUMO_POR_UUID = {url: BASEURL + "schedule/resumoPorUuid", method: "POST"};
export const ENDPOINT_SCHEDULE_VOLTAR_PARA_PENDENTE = {url: BASEURL + "schedule/voltarParaPendente", method: "POST"};

export const ENDPOINT_SERVICO_FILIAL_LISTAR = {url: BASEURL + "servicoFilial/listar", method: "POST"};

export const ENDPOINT_SERVICOS_ATUALIZAR = {url: BASEURL + "servicos/atualizar", method: "PUT"};
export const ENDPOINT_SERVICOS_EXCLUIR = {url: BASEURL + "servicos/excluir", method: "DELETE"};
export const ENDPOINT_SERVICOS_INCLUIR = {url: BASEURL + "servicos/incluir", method: "POST"};
export const ENDPOINT_SERVICOS_PESQUISAR = {url: BASEURL + "servicos/pesquisar", method: "POST"};
export const ENDPOINT_SERVICOS_VISUALIZAR = {url: BASEURL + "servicos/visualizar", method: "POST"};

export const ENDPOINT_TABELA_PRECO_PESQUISAR = {url: BASEURL + "tabelaPrecos/pesquisar", method: "POST"};

export const ENDPOINT_TABELA_24_PESQUISAR = {url: BASEURL + "tabela24/pesquisar", method: "POST"};

export const ENDPOINT_TABELA_27_AUTOCOMPLETE = {url: BASEURL + "tabela27/autocomplete", method: "POST"};
export const ENDPOINT_TABELA_27_PESQUISAR = {url: BASEURL + "tabela27/pesquisar", method: "POST"};

export const ENDPOINT_TIPO_ALTERACAO_EX_CLINICO_PESQUISAR = {url: BASEURL + "tipoAlteracaoExameClinico/pesquisar", method: "POST"};

export const ENDPOINT_UF_ATUALIZAR = {url: BASEURL + "ufs/atualizar", method: "PUT"};
export const ENDPOINT_UF_EXCLUIR = {url: BASEURL + "ufs/excluir", method: "DELETE"};
export const ENDPOINT_UF_INCLUIR = {url: BASEURL + "ufs/incluir", method: "POST"};
export const ENDPOINT_UF_PESQUISAR = {url: BASEURL + "ufs/pesquisar", method: "POST"};
export const ENDPOINT_UF_VISUALIZAR = {url: BASEURL + "ufs/visualizar", method: "POST"};

export const ENDPOINT_UNIDADE_MOVEL_ATUALIZAR = {url: BASEURL + "unidadeMovel/atualizar", method: "PUT"};
export const ENDPOINT_UNIDADE_MOVEL_CANCELAR = {url: BASEURL + "unidadeMovel/cancelar", method: "PUT"};
export const ENDPOINT_UNIDADE_MOVEL_CONCLUIR = {url: BASEURL + "unidadeMovel/concluir", method: "POST"};
export const ENDPOINT_UNIDADE_MOVEL_FINALIZAR = {url: BASEURL + "unidadeMovel/finalizar", method: "POST"};
export const ENDPOINT_UNIDADE_MOVEL_ENUMS = {url: BASEURL + "unidadeMovel/enums", method: "GET"};
export const ENDPOINT_UNIDADE_MOVEL_INCLUIR = {url: BASEURL + "unidadeMovel/incluir", method: "POST"};
export const ENDPOINT_UNIDADE_MOVEL_PESQUISAR = {url: BASEURL + "unidadeMovel/pesquisar", method: "POST"};
export const ENDPOINT_UNIDADE_MOVEL_VISUALIZAR = {url: BASEURL + "unidadeMovel/visualizar", method: "POST"};

export const ENDPOINT_UNIDADE_MOVEL_FUNCIONARIO_ANALISAR_EXAME_MEDICO = {url: BASEURL + "unidadeMovelFuncionario/analisarExameMedicoFuncionario", method: "POST"};
export const ENDPOINT_UNIDADE_MOVEL_FUNCIONARIO_ATUALIZAR = {url: BASEURL + "unidadeMovelFuncionario/atualizar", method: "POST"};
export const ENDPOINT_UNIDADE_MOVEL_FUNCIONARIO_EXCLUIR = {url: BASEURL + "unidadeMovelFuncionario/excluir", method: "DELETE"};
export const ENDPOINT_UNIDADE_MOVEL_FUNCIONARIO_LISTAR = {url: BASEURL + "unidadeMovelFuncionario/listar", method: "POST"};
export const ENDPOINT_UNIDADE_MOVEL_FUNCIONARIO_PENDENCIAS = {url: BASEURL + "unidadeMovelFuncionario/pendencias", method: "POST"};
export const ENDPOINT_UNIDADE_MOVEL_FUNCIONARIO_PESQUISAR = {url: BASEURL + "unidadeMovelFuncionario/pesquisar", method: "POST"};

export const ENDPOINT_USUARIOS_CRIAR_USUARIO_PERFIL_MEDICO = {url: BASEURL + "administrador/criarUsuarioPerfilMedico", method: "POST"};
export const ENDPOINT_USUARIOS_LISTAR_ATIVOS = {url: BASEURL + "administrador/listarUsuariosAtivos", method: "POST"};
export const ENDPOINT_USUARIOS_INATIVAR_CADASTRO = {url: BASEURL + "administrador/inativarUsuario", method: "POST"};
export const ENDPOINT_USUARIOS_RESETAR_SENHA = {url: BASEURL + "administrador/resetarSenha", method: "POST"};

export const ENDPOINT_USUARIOS_SET_FILIAL_LOGADA = {url: BASEURL + "usuarios/setFilialLogada", method: "POST"};
export const ENDPOINT_ALTERAR_SENHA = {url: BASEURL + "usuarios/alterarSenha", method: "PUT"};

export const ENDPOINT_LOGIN = {url: BASEURL + "login", method: "POST"};
export const ENDPOINT_LOGOUT = BASEURL + "logout";
export const ENDPOINT_REFRESH = BASEURL + "refresh";

export const UFS = [
  {sigla: "AC", nome: "Acre", },
  {sigla: "AL", nome: "Alagoas", },
  {sigla: "AP", nome: "Amapá", },
  {sigla: "AM", nome: "Amazonas", },
  {sigla: "BA", nome: "Bahia", },
  {sigla: "CE", nome: "Ceará", },
  {sigla: "DF", nome: "Distrito Federal", },
  {sigla: "ES", nome: "Espírito Santo", },
  {sigla: "GO", nome: "Goiás", },
  {sigla: "MA", nome: "Maranhão", },
  {sigla: "MT", nome: "Mato Grosso", },
  {sigla: "MS", nome: "Mato Grosso do Sul", },
  {sigla: "MG", nome: "Minas Gerais", },
  {sigla: "PA", nome: "Pará", },
  {sigla: "PB", nome: "Paraíba", },
  {sigla: "PR", nome: "Paraná", },
  {sigla: "PE", nome: "Pernambuco", },
  {sigla: "PI", nome: "Piauí", },
  {sigla: "RJ", nome: "Rio de Janeiro", },
  {sigla: "RN", nome: "Rio Grande do Norte", },
  {sigla: "RS", nome: "Rio Grande do Sul", },
  {sigla: "RO", nome: "Rondônia", },
  {sigla: "RR", nome: "Roraima", },
  {sigla: "SC", nome: "Santa Catarina", },
  {sigla: "SP", nome: "São Paulo", },
  {sigla: "SE", nome: "Sergipe", },
  {sigla: "TO", nome: "Tocantins", }
]

export const MESES = [
  {id: 1,  nome: "Janeiro", abreviado: "Jan" },
  {id: 2,  nome: "Fevereiro", abreviado: "Fev" },
  {id: 3,  nome: "Março", abreviado: "Mar" },
  {id: 4,  nome: "Abril", abreviado: "Abr" },
  {id: 5,  nome: "Maio", abreviado: "Mai" },
  {id: 6,  nome: "Junho", abreviado: "Jun" },
  {id: 7,  nome: "Julho", abreviado: "Jul" },
  {id: 8,  nome: "Agosto", abreviado: "Ago" },
  {id: 9,  nome: "Setembro", abreviado: "Set" },
  {id: 10, nome: "Outubro", abreviado: "Out" },
  {id: 11, nome: "Novembro", abreviado: "Nov" },
  {id: 12, nome: "Dezembro", abreviado: "Dez" },
];

export const COR_RACA = [
  {id: 1,  nome: "Branca"},
  {id: 2,  nome: "Preta"},
  {id: 3,  nome: "Amarela"},
  {id: 4,  nome: "Parda"},
  {id: 5,  nome: "Indígena"}
];

export const FILIAIS_GRUPO_TRAMED = [
  {id: 5,  nome: "Consulmed"},
  {id: 2,  nome: "Tramed Bicas"},
  {id: 1,  nome: "Tramed JF"},
  {id: 10, nome: "Tramed SJN"},
  {id: 4,  nome: "Tramed Três Rios"}
];

export const ALFABETO = [
  {id: "A",  nome: "A"},
  {id: "B",  nome: "B"},
  {id: "C",  nome: "C"},
  {id: "D",  nome: "D"},
  {id: "E",  nome: "E"},
  {id: "F",  nome: "F"},
  {id: "G",  nome: "G"},
  {id: "H",  nome: "H"},
  {id: "I",  nome: "I"},
  {id: "J",  nome: "J"},
  {id: "K",  nome: "K"},
  {id: "L",  nome: "L"},
  {id: "M",  nome: "M"},
  {id: "N",  nome: "N"},
  {id: "O",  nome: "O"},
  {id: "P",  nome: "P"},
  {id: "Q",  nome: "Q"},
  {id: "R",  nome: "R"},
  {id: "S",  nome: "S"},
  {id: "T",  nome: "T"},
  {id: "U",  nome: "U"},
  {id: "V",  nome: "V"},
  {id: "W",  nome: "W"},
  {id: "X",  nome: "X"},
  {id: "Y",  nome: "Y"},
  {id: "Z",  nome: "Z"},
];

export const FORMFIELDS = {
  funcionario: {
      doc_cpf: {label: "Cpf", maxLength: 14},
      doc_ctps: {label: "Ctps", maxLength: 20},
      doc_matricula: {label: "Matrícula eSocial", maxLength: 20},
      doc_rg: {label: "Rg", maxLength: 20},
      nome: {label: "Nome", maxLength: 100},
  }
};

export const RESULTADO_EXAME = [
  {id: 'SL', nome: 'Solicitado'},
  {id: 'NR', nome: 'Normal'},
  {id: 'AN', nome: 'Alterado'}
];
